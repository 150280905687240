.Footer{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    padding: auto;
    padding: 1em;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
}
.Footer img{
    max-width: 8em;
    width: 6em;
    margin: auto;
}